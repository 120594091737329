import { Controller } from "@hotwired/stimulus"

export default class NavigateController extends Controller {

  goBack(event){
    if(window.history.length > 1){
      event.preventDefault()
      window.history.back();

      var trans_element = $("#content .tbody")
      if(trans_element.length > 0){
        setTimeout(() => {
          $("#" + trans_element.attr("id"))[0].scrollIntoView({block: "center"});
        }, 30)
      }
    } else {
      // In ios app, modal is a seperate session
      console.error("history is empty")
    }
  }
}
