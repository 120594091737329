import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // console.log('handleCollapse---')
    $(document).on('click', '#trans-table .tbody', this.hideIfClickBlankArea)
    $(document).on('click', '#trans-table .row > .collapse-btn', this.handleCollapse)
    $(document).on('click', '#trans-table .row > .selection-btn', this.handleSelection)
    $(document).on('change', "#select_all_trans", this.handleSelectAll)
  }
  
  handleCollapse = (event) => {

    event.stopPropagation();
    let $this = $(event.target).is('.collapse-btn') ? $(event.target) : $(event.target).closest('.collapse-btn')
    // console.log($this.is('.collapse-btn'), $(this).is('.selection-btn'), event.target)
    event.preventDefault();

    if ($this.parents('tbody').hasClass('expanded')) {
      var form = $(this).parents('tr').next().find('form')
      if (form.data('dirty')) {
        showMessage("You have pending changes for this transaction. Please either submit the changes or discard them.", 'error')
        smoothScrollTo(form);
      } else {
        this.toggleCollapse($this);
      }
    } else {
      this.toggleCollapse($this);
    }
  };

  hideIfClickBlankArea = (e) =>{
     // Hide  tran detail & form on click blank area
    var $target = $(e.target)
    if($("body").hasClass("datetimepicker-showing")){
      return
    }

    var selector = "label, button, a, .form-control, :input, .direct-chat-text"
    if(!$target.is(selector) && $target.parents(selector).length === 0 ){
      this.toggleCollapse($target.closest('.tbody').find('.tran-row'));
    }else if($target.is(".finalized-cancel-btn") || $target.parents(".finalized-cancel-btn").length === 1) {
      this.toggleCollapse($target.closest('.tbody').find('.tran-row'));
    }
  }


  handleSelection = (event)=> {
    let $this = $(event.target).is('.selection-btn') ? $(event.target) : $(event.target).closest('.selection-btn')
    this.toggleSelection($this);
  }

  toggleCollapse(trigger, slideUpDuration, completedCallback){
    var tr = trigger.is('.tr') ? trigger : trigger.parents('.tr');
    var tbody = tr.closest('.tbody');
    slideUpDuration = slideUpDuration || 400
  
    var content = tr.next('.tr[data-collapse-content]');
    if (content.is(':visible')) {
      if (slideUpDuration == 0) {
        tbody.removeClass('expanded');
        content.hide();
        tr.fadeIn();
      } else {
        content.find('div[data-collapse-content-container]').slideUp(slideUpDuration, function() {
          tbody.removeClass('expanded');
          content.hide();
          tr.fadeIn();
  
          if (completedCallback) {
            completedCallback.call();
          }
        });
      }
      
      return
    }
    tbody.addClass('expanded')
    tr.hide();
    content.show();
  
    if (slideUpDuration > 0) {
      smoothScrollTo(tbody, function(){ })
      content.find('div[data-collapse-content-container]').slideDown(slideUpDuration, function() {
        content.find('div[data-collapse-content-container] textarea.desc').select();
        
        if (completedCallback) {
          completedCallback.call();
        }
      });
    }
  }


  handleSelectAll = (event) => {
    let isChecked = $(event.target).prop("checked") 
    if(isChecked) {
      $("#trans-table .tran-uuid .selection-checkbox").prop("checked", isChecked)
      this.reloadingPreviewSettlements(true)
    } else {
      clearSelections()
    }
  }

  toggleSelection($trigger) {
    var checkbox;
  
    checkbox = $trigger.find('.selection-checkbox');
    checkbox.prop('checked', !checkbox.prop('checked'));
  
    var tr = $trigger.parents('tr').addBack();
    tr.toggleClass('selected', checkbox.prop('checked'));
    
    this.reloadingPreviewSettlements()
  }



  reloadingPreviewSettlements(fromSelectAll){
    var uuids = [];
    $('.selection-checkbox:checked').each(function(i, x) {
      uuids.push($(x).val());
    });

    // update select all checkbox status
    if(!fromSelectAll){
      $('.select-all-checkbox').prop("checked", uuids.length === $('.selection-checkbox').length)
    }

    let $preview = $('#settlement-preview')
    if($preview.length === 1){
      $preview.find(".not_approveds_count").text(uuids.length)
      $preview.find(".not_approveds_amount").addClass('invisible')
      $preview.find(".spinner-border").removeClass('invisible')
    }

    $.ajax({
      type: 'POST',
      url: PREVIEW_SETTLEMENTS_PATH,
      data: {uuids: uuids.join(',')}
    }).always(function() {
      hideLoading();
    }).fail(function(xhr, status, error) {
      ajaxErrorHandler(xhr);
    });
}

  disconnect(){
    $(document).off('click', '#trans-table .tbody', this.hideIfClickBlankArea)
    $(document).off('click', '#trans-table .row > .collapse-btn', this.handleCollapse)
    $(document).off('click', '#trans-table .row > .selection-btn', this.handleSelection)
    $(document).off('change', "#select_all_trans", this.handleSelectAll)
  }

}

