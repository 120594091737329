import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    this.rotatation = 0
  }

  rotateLeft(){
    this.resetCurrentRotation()
    this.rotation = this.rotation - 90
    this.updateRotation()
  }

  rotateRight(){
    this.resetCurrentRotation()
    this.rotation = this.rotation + 90
    this.updateRotation()
  }

  updateRotation(){
    this.rotation = this.rotation % 3600
    const img = this.element.querySelector('.modal-body img')

    if(!img) return
    const maxBorder = Math.max(img.width, img.height) + 10
    img.style.transitionDuration = "0.5s"
    img.style.transform = `rotate(${this.rotation}deg)`

    $(this.element).modal('handleUpdate')
  }
  
  resetCurrentRotation(){
    const img = this.element.querySelector('.modal-body img')
    if(img && img.style.transform){
      this.rotation = parseInt(img.style.transform.match(/\((\-?\d+)deg/)[1])
    } else {
      this.rotation = 0
    }
    // console.log(this.rotation)
  }

  disconnect(){
    // this.element.removeEventListener('hide.bs.modal')
  }
}