import { Controller } from "@hotwired/stimulus"
export default class ModalController extends Controller {
  closeOnSuccess({ detail: { success } }) {
    if (success) {
      $(this.element).modal('hide')
    }
  }
  
  close(){
    $(this.element).modal('hide')
  }

  show(){
    var $this = $(this.element)
    if ($this.hasClass("modal")){
      $this.modal('show')
    } else {
      $($this.attr("data-target")).modal("show")
    }
  }

  disconnect() {
    $(this.element).modal('hide')
  }
}