// import { Turbo } from "turbo"
import { Controller } from "@hotwired/stimulus"

 export default class MobileController extends Controller {
  connect() {
    $(document).on("click", '[data-url]:not([data-action])', this.navigateByDataUrl)
  }

  navigateByDataUrl(event){
    if($(this).attr('data-action')) return;
    event.preventDefault()
    event.stopPropagation();
    var url = $(this).data('url')
    Turbo.visit(url)
  }

  disconnect(){
    $(document).off("click", '[data-url]:not([data-action])', this.navigateByDataUrl)
  }

}

