/* jshint esversion: 6 */
import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';
import dayjs from 'dayjs'
import autoComplete from "@tarekraafat/autocomplete.js";
import Tribute from "tributejs";

export default class TransFormController extends Controller {
  connect() {
    // console.log($.fn)
    bindUI() // turbo lazy load edit form
    var $this = $(this.element)
    this.is_new_tran = $this.attr("id") === "new_tran"
    this.is_new_tran && this.update_tran_desc_field_placeholder()
    var $tran_desc = $this.find('#tran_desc.auto-complete')

    $tran_desc[0] && this.autocompleteDesc({tran_desc: $tran_desc[0]})
  }

  // autocomplete desc and search keywords
  autocompleteDesc(opt) {
    const {tran_desc} = opt
    fetch(tran_desc_auto_complete_data_url)
    .then(response => response.json() )
    .then( autocompleteData => {
      // console.log(autocompleteData)
      const {descs, tags} =  autocompleteData
      const autoCompleteJS = new autoComplete({
          selector: '#tran_desc',
          data: {
              src: descs,
              cache: true,
          },
          resultItem: {
              highlight: true
          },
          // select first item https://github.com/TarekRaafat/autoComplete.js/issues/241
          resultsList: {
            element: (list, data) => {
              setTimeout(() => autoCompleteJS.goTo(0), 10);
            }
          },
          events: {
              input: {
                  selection: (event) => {
                      const selection = event.detail.selection.value;
                      autoCompleteJS.input.value = selection;
                  }
              }
          }
      });

      const tagTribute = new Tribute(
        { 
          trigger: '#', replaceTextSuffix: '\n', 
          autocompleteMode: false, noMatchTemplate: '',
          spaceSelectsMatch: true,
          values: tags.map(x => ({key: x, value: x }) ) 
        }
      );
      // console.log({tran_desc, tags})
      // autoCompleteTribute.attach(tran_desc);
      tagTribute.attach($('#keyword')[0]);
      tagTribute.attach(tran_desc);

      // show auto complete on focus
      // $(tran_desc).on("focus", () => {
        // autoCompleteTribute.showMenuForCollection(tran_desc)
        // autoCompleteJS.open();
      // })
    })
  }

  tran_photo_changed(event) {
    var file_field = event.target
    $(file_field).parents('.custom-image-upload').addClass('photo-selected')
    var form = $(file_field).parents('form')

    loadImage(
      file_field.files[0],
      (img, data) => {
        form.find('.tran_photo_preview').show().html(img)
        form.find('.tran_photo_camera').hide()
        if (this.is_new_tran && data.exif) {
          var date_time = data.exif.get("DateTime")
          if (date_time) {
            console.debug("Update date input value to image exif date")
            // mobile use native date-picker format must be yyyy-MM-DD
            date_format = isMobile() ? 'yyyy-MM-DD' : window.date_format
            console.log(date_time)
            date_time = new Date(date_time.split(" ")[0].split(":").join("/"))
            form.find('input#tran_date').val(dayjs(date_time).format(date_format))

            form.find('select#tran_action').val('they_owe').trigger('change')
            form.find("input[value='they_owe']").trigger("click")
          }
        }
      },
      {
        maxWidth: 50,
        maxHeight: 50,
        meta: this.is_new_tran
      }
    )
  }

  cancel_changes(event){
    event.preventDefault();
    var form = $(event.target).parents('form');

    form.trigger('reset');
    dayjs.locale(window.locale)
    form.find('input.date').val(dayjs(form.find('input.date').val()).format(window.date_format));

    form.find('input:submit').attr('disabled', 'disabled');
    // form.find('.cancel-changes-btn').attr('disabled', 'disabled');
    // form.find('.cancel-changes-btn').html('Cancel');
    form.data('dirty', false);
    if(isMobile()){
      var backUrl = $("nav.navbar .btn-go-back").attr("href") || document.referrer
      Turbo.visit(backUrl)
    } else {
      this.trans_table_controller.toggleCollapse(form.closest('.tbody').find('.tran-row'));
    }
  }

  update_tran_desc_field_placeholder(event) {
    // var $form =  $('form#new_tran')
    var $form = event ? $(event.target).closest("form") : $('form#new_tran')
    var tran_desc = $form.find('#tran_desc')
    var tran_amount = $form.find('#tran_amount')
    var tran_action_val =  $form.find('#tran_action').val() || $form.find('.tran_action:checked').val()

    if (tran_action_val && tran_action_val.match(/add_note/)) {
      tran_desc.attr('placeholder', window.i18n_json.add_note_description)
      tran_amount.slideUp() //hide()
      $form.find(".currency_select").slideUp()
    } else {
      if (tran_action_val && tran_action_val.match(/paid/)) {
        tran_desc.attr('placeholder', window.i18n_json.transaction_comments)
      } else {
        tran_desc.attr('placeholder', window.i18n_json.for_what)
      }
      tran_amount.removeClass("d-none").slideDown()
      $form.find(".currency_select").removeClass("d-none").slideDown()
    }
  }

  // https://github.com/hotwired/turbo/issues/154
  approve(event){
    event.preventDefault()
    const $trigger = $(event.target)
    const frame = $trigger.closest(".tbody").closest("turbo-frame").attr("id")
    const token = $('[name=csrf-token]').attr("content")

    fetch($trigger.attr("data-url"),  {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'X-Csrf-Token': token,
        "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
        "Turbo-Frame": frame
      }
    }).then(response => {
      // HTTP 301 response
      if (response.redirected) {
        Turbo.visit(response.url);
      } else {
        showMessage(response.statusText, "error")
      }
    })

  }

  tran_action_changed(e){
    var currentEl = $(e.currentTarget)
    this.update_tran_desc_field_placeholder()
    var $tran_actions = currentEl.closest(".tran-actions")
    $tran_actions.find(".tran-action-btn").removeClass("btn-info active-action")
    $tran_actions.find("input[type='radio']:checked").closest(".btn").addClass("btn-info active-action")
  }

  create(event){
    event.preventDefault()
    event.stopPropagation()
    Rails.disableElement($("#new_tran")[0])

    var $form = $(event.currentTarget)
    var form = event.currentTarget

    $.ajax({
      type: "POST",
      url: form.action,
      data: new FormData(form),
      dataType: "script",
      processData: false,
      contentType: false
    }).fail( (xhr) => {
      ajaxErrorHandler(xhr)
    }).done(() => {
      var currency = $(".currency_select select").val()
      $(this.element).trigger('reset');
      this.resetCustomInput()

      // remember currency
      $(".currency_select select").val(currency)
      $(this.element).closest(".modal").modal("hide")
    }).always(() => {
      Rails.enableElement($("#new_tran")[0])
    })
  }


  resetCustomInput(){
    var $this = $(this.element)
    $this.find("input[type=file]").val("")
    $this.find(".tran_photo_preview").hide()
    $this.find('.tran_photo_camera').show()

    // reset action
    $this.find('.tran-actions .btn-info.active-action :input').trigger('click')
  }

  get trans_table_controller() {
    // https://github.com/hotwired/stimulus/issues/35
    return this.application.getControllerForElementAndIdentifier($(this.element).closest("#trans-table")[0], "trans-table")
  }
}
