/* jshint esversion: 6 */
import { Controller } from "@hotwired/stimulus"

export default class DownloadImagesFormController extends Controller {
  connect() {
    var $this = $(this.element)
    
    // check if support date-picker
    var datePickerSelector = '.date-picker'

    $(datePickerSelector).datepicker({
      keyboardNavigation: false,
      language: window.locale,
      todayHighlight: true,
      autoclose: true,
      format: 'yyyy-mm-dd'
    }).on('changeDate', function(event) {
    }).on('show', function(event){
    }).on('hide', function(event){
    })

    let download_zip_url = $this.data('download-images-url')

    if (download_zip_url) {
      location.href = download_zip_url
    }
  }
}
