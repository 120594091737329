import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect(){
    // console.log("connected 0000 select visitor")
  }
  visit(event){
    console.log(event.target.value)
    Turbo.visit(event.target.value)
  }
  
  visit_ledger(event){
    console.log("visit_ledger", event.target.value)
    Turbo.visit(location.href.replace(/\/ledgers\/\d+.*/, "\/ledgers/" + event.target.value) )
  }
}