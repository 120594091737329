import { Controller } from "@hotwired/stimulus"

export default class FilterController extends Controller {

  connect(){ 
    // console.log("FilterController connected")
    // $(document).on("blur", ".trans-filter :input", this.slideUpFilterInputOnBlur)
  }
  
  disconnect(){
    $(document).off("blur", ".trans-filter :input", this.slideUpFilterInputOnBlur)
  }

  toggleNewTranForm(e){
    e.preventDefault()
    // console.log("clicked- ---", e.currentTarget)
    var $new_tran = $("#new_tran")
    var $btn_icon = $(e.currentTarget).find(".fas")

    if($btn_icon.hasClass("fa-plus")){
      this.slideUpFilterInputs()
      $new_tran.hide().removeClass("d-none").slideDown(function(){
        $("#new_tran [autofocus]").focus()
      })
      // $(".trans-filter :input").attr("disabled", "disabled")
    } else {
      $new_tran.slideUp()
      // this._enableFilterInputs()
    }
    $btn_icon.toggleClass("fa-minus fa-plus")
  }


  slideUpNewTran(){
    $("#new_tran").slideUp()
    $(".add-tran .fas").removeClass("fa-minus").addClass("fa-plus")
  }
  
  slideDownFilterInputs(){
    if($(".filter-input-group:visible").length > 0) return
    $(".filter-input-group").hide().removeClass("d-none").slideDown("slow")
  }

  slideUpFilterInputs(){
    $(".filter-input-group").slideUp()
  }


  slideUpFilterInputOnBlur = () => {
    var prevActiveElement = document.activeElement
    setTimeout(() => {
      var activeEl = document.activeElement
      // console.log(activeEl, "-----",  prevActiveElement)
      if(activeEl && $(activeEl).closest(".trans-filter").length === 0){
        this.slideUpFilterInputs()
      }
    }, 10)
  }


  openBottomSheet(e){
    e.preventDefault()
    $("#new-tran-bottom-sheet").modal("show")
  }

  inputKeydown = (x) => {
    // if (x.keyCode == 13) {
    //   $('form#search_form').submit()
    // }
  }


  // private methods 

  _enableFilterInputs(){
    $(".trans-filter :input").attr("disabled", false)
  }


}