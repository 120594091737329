/* eslint no-console:0 */
/* jshint esversion: 6 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb



// Uncomment to copy all static images under ../images to the output folder and reference
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "core-js/stable"
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import "regenerator-runtime/runtime"
window.fuzzysort = require('fuzzysort')
// import {} from 'jquery-ujs'
import Rails from '@rails/ujs';
Rails.start();

import 'jquery-toast-plugin'

import dayjs from 'dayjs'
window.dayjs = dayjs
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const moment = dayjs

import "selectize"
import ClipboardJS from 'clipboard'
require('bootstrap');
import "../src/libraries/unobtrusive_flash"
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.es';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.zh-CN';

import loadImage from 'blueimp-load-image/js/load-image'
require('blueimp-load-image/js/load-image-exif')
require('blueimp-load-image/js/load-image-meta')
require('blueimp-load-image/js/load-image-scale')
window.loadImage = loadImage

import { Turbo } from "@hotwired/turbo-rails"
// delayInMilliseconds
Turbo.setProgressBarDelay(200)


function isMobile() {
  return $('body').hasClass('mobile');
}
window.isMobile = isMobile;


function bindUI() {

  // console.log('-----', $.fn.datepicker)
  // test whether a new date input falls back to a text input or not
  var test = document.createElement('input');
  try {
    test.type = 'date';
  } catch (e) {
    console.log(e.description);
  }

  $(".seletize-tags").selectize({
    delimiter: ",",
    persist: false,
    create: function (input) {
      return {
        value: input,
        text: input,
      };
    },
  });

  // https://github.com/hotwired/turbo/issues/173 Turbo break ios date input
  if(window.is_safari){
    $("[type=date]").attr("type", "date")
    // document.querySelector("[type='date']").setAttribute("type", "date")
  }

  // check if support date-picker
  var datePickerSelector = test.type === 'text' ? '.date-picker, [type=date]' : '.date-picker'

  var _utc_to_local = function (utc) {
    return utc && new Date(utc.getTime() + (utc.getTimezoneOffset() * 60000));
  }
  var _local_to_utc = function (local) {
      return local && new Date(local.getTime() - (local.getTimezoneOffset() * 60000));
  }
  $(datePickerSelector).datepicker({
    keyboardNavigation: false,
    language: window.locale,
    format: {
      toDisplay: function (date, format, language) {
        // console.log("toDisplay ----", date)
        var d = moment(_utc_to_local(date)).format(window.date_format)
        // console.log("toDisplay", d)
        return d
      },
      toValue: function (date_string, format, language) {
        // console.log("toValue ----", date)
        var d;
        if(moment(date_string, window.date_format, true).isValid()){
          d = moment(date_string, window.date_format).toDate()
        } else {
          d = new Date(date_string)
        }
        d = _local_to_utc(moment.utc(d).toDate())
        // console.log("toValue", d, date)
        return d
      }
    },
    todayHighlight: true,
    autoclose: true,
  }).on('changeDate', function(event) {
    enableEditTranSubmitButton($(event.target));
  }).on('show', function(event){
    $('body').addClass('datetimepicker-showing')
  }).on('hide', function(event){
    // Let other event callback have time to check
    $(event.target).blur()
    setTimeout(function(){
      $('body').removeClass('datetimepicker-showing')
    }, 50)
  })

  $(datePickerSelector).each(function(){
    var $this = $(this)
    $this.attr("autocomplete", "off")
    var date = $this.val()
    if(date) {
      if(!moment(date, window.date_format, true).isValid()){
        $this.val(moment(date).format(window.date_format))
      }
    } else {
      $this.datepicker("update", moment().format(window.date_format) )
    }
  });

  if(!isMobile()){
    $('select.tran-unit').not(".selectized").selectize({create: true, wrapperClass: 'selectize-control'});
  }
  $('[data-toggle="tooltip"]').tooltip();
}
window.bindUI = bindUI;

function enableEditTranSubmitButton(trigger) {
  var form = trigger.parents('form.edit-tran-form');
  form.find('input:submit').removeAttr('disabled');
  // form.find('.cancel-changes-btn').removeAttr('disabled');
  // form.find('.cancel-changes-btn').html('Discard');
  form.data('dirty', true);
}

function showMessage(message, type) {
  $.toast({
    heading: '',
    text: message,
    position: 'top-center',
    icon: type,
    stack: false,
    loader: false,
    allowToastClose: false,
    hideAfter: 2000
  });
}

var flashHandler = function(e, params) {
  showMessage(params.message, params.type)
  $.toast({
      heading: '',
      text: params.message,
      position: 'top-center',
      icon: params.type,
      stack: false,
      loader: false,
      allowToastClose: false,
      hideAfter: 2000
  })
};


function ajaxErrorHandler(xhr) {
  if (xhr.status == 422) {
    try {
      var h = JSON.parse(xhr.responseText);
      if (h.message) {
        showMessage(h.message, 'error');
      }
    } catch(e) {
      console.info(xhr.responseText);
      console.info(e);
    }
  } else {
    alert(xhr.responseText || xhr.statusText )
  }
}
window.ajaxErrorHandler = ajaxErrorHandler;


async function fetchFailedHandler(response){
    // console.log("------------", await fetchResponse.responseText)
    var xhr = {
      status: response.status,
      ok: response.succeeded,
      responseText: await response.text(),
      statusText: response.statusText
    }
  ajaxErrorHandler(xhr)
}

function showLoading() {
  $('body').before($('<div class="turbolinks-spinner">Loading&#8230;</div>'));
}

function hideLoading() {
  $(document).find('.turbolinks-spinner').remove();
}
window.hideLoading = hideLoading;

function smoothScrollTo($target, callback) {
  $('html, body').stop().animate({scrollTop: $target.offset().top - 50}, 400, 'linear', callback);
  // $target[0].scrollIntoView({behavior: "smooth", block: "center"});
}
window.smoothScrollTo = smoothScrollTo;

function clearSelections() {
  $('#trans-table .selection-checkbox:checked').each(function(i, x) {
    $(x).prop('checked', false);
  });

  $('#trans-table .select-all-checkbox').prop("checked", false)

  $('#settlement-preview').fadeOut(function(){
    $(this).remove();
  })
  $('.tran-row.selected').removeClass('selected');
}


window.addEventListener("turbo:load", function() {
  // bindUI()
  // in desktop modal content is lazy loded
  var clipboardContainer = document.getElementById('ledger-settings-modal-content') || document.getElementById ('ledger-settings-modal')
  var clipboard = new ClipboardJS('.btn-clipboard', {container: clipboardContainer});
  clipboard.on('error', function(e) {
    alert('Copy failed.')
  })
  clipboard.on('success', function(e) {
    $(e.trigger).tooltip({title: 'Copied!'})
    $(e.trigger).tooltip('enable').tooltip('show')
    setTimeout(function() {
      $(e.trigger).tooltip('hide').tooltip('disable')
    }, 1000)
  })

  if(isMobile()){
    // scroll-into-view
    var active_ledger = $("ul.ledgers_nav .active")[0]
    active_ledger && active_ledger.scrollIntoView({block: 'center'})
  } else {
    var tbody = $('.tbody.expanded');
    if (tbody.length) {
      setTimeout(function() {
        smoothScrollTo(tbody)
      }, 500);
    }
  }
})

$(function () {
  $(document).on('click', '#tabs-list-toggler', function() {
    $('#account-list').collapse('hide');
  });

  $(document).on('click', '#account-list-toggler', function() {
    $('#tabs-list').collapse('hide');
  });

  // Selection transactions
  $(document).on('click', '#clear-selections-btn', function(event) {
    event.preventDefault();
    clearSelections();
  });

  if(!isMobile()){
    $(document).on('click', 'a.img-modal-link', function(event) {
      event.preventDefault()
      var a = $(event.target).parents('a').addBack()
      var url = a.attr('href')
      $('#img_modal .modal-body').html('<img src="' + url + '" class="img-fluid" />')
      $('#img_modal').modal('show')
    })
  }

  $(document).on('keyup change', 'form.edit-tran-form input, form.edit-tran-form select, form.edit-tran-form textarea', function(event) {
    // set trans update to true if changed tran's atttibutes
    if(/^tran\[/.test(event.currentTarget.getAttribute("name"))){
      $(this).closest("form").find(".updated-hidden-field").val("1")
    }
    enableEditTranSubmitButton($(event.target));
  });

  $(document).on('ajax:before', function() {
    showLoading();
  });
  $(document).on('ajax:complete', function() {
    hideLoading();
  });
  $(document).on('ajax:error', function(xhr, status, error) {
    ajaxErrorHandler(xhr);
  });

  $(document).on('turbo:before-fetch-response', function(event) {
    var fetchResponse = event.originalEvent.detail.fetchResponse
    // console.log("turbo:before-fetch-response", event.detail, fetchResponse)
    if(fetchResponse.failed){
      fetchFailedHandler(fetchResponse.response.clone());
    }

    // if(!fetchResponse.redirected){
      UnobtrusiveFlash.showFlashFromCookies()
    // }
  });




  // https://github.com/hotwired/turbo/issues/37
  let scrollTop = 0
  document.addEventListener("turbo:click", ({ target }) => {
    if (target.getAttribute("data-turbo-preserve-scroll")) {
      scrollTop = document.scrollingElement.scrollTop
    }
  })

  document.addEventListener("turbo:load", () => {
    if (scrollTop) {
      document.scrollingElement.scrollTo(0, scrollTop)
    }
    scrollTop = 0
  })


  $(window).bind('rails:flash', flashHandler);

  // Fix turbo drive cache
  $(document).on("click", '.ledgers_nav .nav-item > a', function(){
    $(this).closest("ul").find("a.active").removeClass("active")
    $(this).addClass("active")
  })

  // Only allow last input use Enter to submit form
  $(document).on("keydown", ":input:not(textarea)", function(event) {
    var $target = $(event.target)
    // Allow submit if all inputs have value
    if (event.key === "Enter"){
      var ignoreTypes = ".btn, [type='file'], [type='checkbox'], [type='submit']"
      var allInputHasValue = $target.closest("form").find(`:visible:input:not(${ignoreTypes})`).filter(function () {
        if($(this).closest(".selectize-input").length > 0 ) return false // seletize
        // console.log("val", $(this).val())
        return $.trim($(this).val()).length == 0
      }).length == 0;

      if(allInputHasValue) return true
    }

    if (event.key === "Enter" && $target[0] !== $target.closest("form").find(`:visible:input:not(${ignoreTypes}):last`)[0] ){
      return false
    }

    return true
  });

  $(document).on('shown.bs.modal', function(event) {
    $(event.target).find('input[autofocus]').focus();
  });

  if (window.FileList && window.File && window.FileReader) {
    $('input#account_avatar').on('change', function(event) {
      var file = event.target.files[0];
      if (!file.type) {
        console.debug('Error: The File.type property does not appear to be supported on this browser.');
        return;
      }
      if (!file.type.match('image.*')) {
        console.debug('Error: The selected file does not appear to be an image.');
        return;
      }
      var reader = new FileReader();
      reader.addEventListener('load', function(event) {
        $('.user-avatar').html('').css({width: '100px', height: '100px', backgroundImage: 'url(' + event.target.result + ')'}).addClass('border rounded border-secondary');
      });
      reader.readAsDataURL(file);
    });
  }
});
document.addEventListener("turbo:before-cache", function() {
  $(".ledgers_nav li .active").removeClass("active")
})

document.addEventListener("DOMContentLoaded", event => {
  const observer = new MutationObserver( (mutationsList, observer) => {
    let showProgressBar = false
    for(const mutation of mutationsList) {
      // console.log(mutation, mutation.oldValue)
      if(mutation.attributeName !== "busy") continue;
      if(mutation.oldValue === null && mutation.target.getAttribute("busy") === ""){
        showProgressBar = true
        break;
      }
    }
    // console.log("showProgressBar", showProgressBar)

    if(showProgressBar) {
      showLoading();
    } else {
      hideLoading();
    }
  });

  // Start observing the target node for configured mutations
  document.querySelectorAll("turbo-frame[show-progressbar]").forEach(frameNode => {
    observer.observe(frameNode, { attributes: true, attributeFilter: ['busy'] } );
  })


  document.addEventListener('turbo:frame-render', () => UnobtrusiveFlash.showFlashFromCookies() )
  document.addEventListener("turbo:render",  () => {
    UnobtrusiveFlash.showFlashFromCookies()

    // fix hideafter not working in jquery-toast-plugin
    setTimeout(() => $(".jq-toast-wrap").remove(), 3000)
  })

  document.addEventListener("turbo:submit-end", function(event){
    // console.log('turbo:submit-end', event.detail.fetchResponse.response)
    // wait page render, fix showFlashFromCookies in frame-render don't work properly
    setTimeout(() => UnobtrusiveFlash.showFlashFromCookies(), 300)
    // debugger
    // Rails.enableElement(event.originalEvent && event.originalEvent.composedPath()[0])
    Rails.enableElement(event.target)
  })
})


// Later, you can stop observing
// observer.disconnect();


// A temporary fix for scrollRestore
// https://github.com/hotwired/turbo/issues/171#issuecomment-832096863
/*
class ScrollPosition {
  save() {
    document.body.dataset.scrollPosition = JSON.stringify([window.scrollX, window.scrollY]);
  }

  restore() {
    const scrollPosition = JSON.parse(document.body.dataset.scrollPosition || '[]');
    if (scrollPosition.length > 0) {
      window.scrollTo(...scrollPosition);
    }
    delete document.body.dataset.scrollPosition;
  }
}

const scrollPosition = new ScrollPosition();

document.addEventListener('turbo:before-cache', function (_event) {
  scrollPosition.save();
});

document.addEventListener('turbo:load', function (_event) {
  scrollPosition.restore();
});
*/

import "controllers";
