import { Controller } from "@hotwired/stimulus"
import { decode, isBlurhashValid } from "blurhash";

export default class extends Controller {
  static values = { code: String };
  static targets = [ "img" ];

  connect() {
    console.log("blurhash valid?", isBlurhashValid(this.codeValue).result)
    if (isBlurhashValid(this.codeValue).result !== true) {
      this.showImg()
      return
    }

    try{
      const width = 32 , height = 32;
      const pixels = decode(this.codeValue, width, height);

      const canvasWrapper = document.createElement("div");
      canvasWrapper.style.cssText = "position: absolute; left: 0; top: 0; width: 100%; height: 100%;"
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      const imageData = ctx.createImageData(width, height);
      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);

      canvasWrapper.append(canvas);
      this.element.prepend(canvasWrapper);
    } catch(e){
      console.log(e)
      this.showImg()
    } finally{
      if(this.imgTarget.complete) {
        this.showImg();
      }
    }
  }

  showImg(){
    // console.log("img loaded...", this.imgTarget.classList)
    this.imgTarget.classList.remove("loading-inprogress")
  }
}
